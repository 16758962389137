/**
 * 포털에서 사용할 API url
 *
 */

const baseApiUrl = {
  USER   : '/api/user/v1',
  MANAGER: '/api/manager/v1',
};

const portalApiUrl = {

  // 사용자
  USER: {

    // 앱 컨트롤러 ( PortalUserAppAPIController )
    APP_DOWN_AUDIT: {
      INSERT: `${ baseApiUrl.USER }/portalUserAppApi_insertAuditAppDown`, // 앱 다운로드 이력 등록
    },

    // 게시판 컨트롤러 ( PortalUserBoardAPIController )
    // 게시판
    BOARD: {
      LIST_BOARD : `${ baseApiUrl.USER }/portalUserBoardApi_boardList`, // 게시판 리스트
      GET_BOARD  : `${ baseApiUrl.USER }/portalUserBoardApi_getBoardInfo`, // 게시판 가져오기
      EXIST_BOARD: `${ baseApiUrl.USER }/portalUserBoardApi_existBoard`, // 존재하는 게시판인지 여부
    },

    // 게시글
    POSTING: {
      LIST                             : `${ baseApiUrl.USER }/portalUserBoardApi_listPosting`, // 게시글 리스트
      LIST_WITH_AUTH                   : `${ baseApiUrl.USER }/portalUserBoardApi_listPostingWithAuth`, // Authentication 게시글 리스트
      VIEW_WITH_PREV_AND_NEXT          : `${ baseApiUrl.USER }/portalUserBoardApi_viewWithPrevAndNext`, // 게시글 상세보기
      VIEW_WITH_PREV_AND_NEXT_WITH_AUTH: `${ baseApiUrl.USER }/portalUserBoardApi_viewWithPrevAndNextWithAuth`, // Authentication 게시글 상세보기
      GET                              : `${ baseApiUrl.USER }/portalUserBoardApi_getPosting`, // 게시글 가져오기
      INSERT_OR_UPDATE                 : `${ baseApiUrl.USER }/portalUserBoardApi_savePosting`, // 게시글 등록/수정
      DELETE                           : `${ baseApiUrl.USER }/portalUserBoardApi_deletePosting`, // 게시글 단건 삭제
      DELETE_BY_POSTING_OID_LIST       : `${ baseApiUrl.USER }/portalUserBoardApi_deletePostingList`, // 게시글 다건 삭제
      CHECK_FORBIDDEN_WORD             : `${ baseApiUrl.USER }/portalUserBoardApi_checkForbidden`, // 금칙어 확인
      CHECK_PWD                        : `${ baseApiUrl.USER }/portalUserBoardApi_pwdCheck`, // 게시글 비밀번호 일치여부
      RECOMMEND_ACTION                 : `${ baseApiUrl.USER }/portalUserBoardApi_recommendAction`, // 게시글 추천기능
    },

    // 답글
    COMMENT: {
      LIST            : `${ baseApiUrl.USER }/portalUserBoardApi_listComment`, // 답글 리스트
      INSERT_OR_UPDATE: `${ baseApiUrl.USER }/portalUserBoardApi_insertOrUpdateComment`, // 답글 등록/수정
      DELETE          : `${ baseApiUrl.USER }/portalUserBoardApi_deleteComment`, // 답글 삭제
    },

    // NEIS 본인인증 API
    CERTIFICATION: {
      GET_ENC_DATA   : `${ baseApiUrl.USER }/portalUserCertification_getEncData`, // 본인인증 서비스 호출을 위한 데이터를 세팅합니다.
      SUCCESS_PROCESS: `${ baseApiUrl.USER }/portalUserCertification_successProcess`, // 인증 성공시 받은 인코딩데이터를 복화화한 후 반환합니다.
    },

    // 코드 컨트롤러 ( PortalUserCodeAPIController )
    CODE: {
      LIST_ALL_ORG_CODE     : `${ baseApiUrl.USER }/portalUserCode_listAllOrgCode`, // 코드 목록 : ORG CODE 리스트를 불러옵니다.
      LIST_ALL_AREA_CODE    : `${ baseApiUrl.USER }/portalUserCode_listAllAreaCode`, // 코드 목록 : AREA CODE 리스트를 불러옵니다.
      LIST_ALL_RANK_CODE    : `${ baseApiUrl.USER }/portalUserCode_listAllRankCode`, // 코드 목록 : 신분분류 리스트를 불러옵니다.
      LIST_ALL_IDENTITY_CODE: `${ baseApiUrl.USER }/portalUserCode_listAllIdentityCode`, // 코드 목록을 불러옵니다.
    },

    // GPKI 컨트롤러 ( PortalUserGpkiAPIController )
    GPKI: {
      GET_GPKI_AUTHENTICATION: `${ baseApiUrl.USER }/portalUserGpkiApi_getGpkiAuthentication`, // GPKI 인증 값을 받아옵니다.
      GPKI_LOGIN             : `${ baseApiUrl.USER }/portalUserGpkiApi_gpkiLogin`, // 로그인해서 받은 값을 추출합니다.
    },

    // 홈페이지(연혁 및 주요업무 ) 컨트롤러 ( PortalUserHomepageHistoryAPIController )
    HOMEPAGE_HISTORY: {
      LIST_ALL_HISTORY: `${ baseApiUrl.USER }/portalUserHomepageHistoryApi_historyListAll`, // 연혁 리스트를 불러옵니다.
      LIST_ALL        : `${ baseApiUrl.USER }/portalUserHomepageHistoryApi_listAll`, // 연혁 및 주요 업무 전체 목록을 가져옵니다.
    },

    // 로그인 컨트롤러 ( PortalUserLoginAPIController )
    LOGIN: {
      LOGIN: `${ baseApiUrl.USER }/portalUserLoginApi_login`, // 로그인
      INVALIDATE_PREVIOUS_SESSION: `${ baseApiUrl.USER }/portalUserLoginApi_invalidatePreviousSession` // 이전 세션 제거
    },

    // 로그인 이력 컨트롤러 ( PortalUserLoginAuditAPIController )
    LOGIN_AUDIT: {
      INSERT: `${ baseApiUrl.USER }/portalUserLoginAuditApi_insert`, // 로그인 이력 저장
      LIST_LOGIN_HISTORY: `${baseApiUrl.USER}/portalUserApi_listLoginHistory`, // 유져의 로그인 이력을 가져옵니다.
    },

    // 로그아웃 컨틀로러 ( PortalUserLogoutAPIController )
    LOGOUT: {
      LOGOUT: `${ baseApiUrl.USER }/portalUserLogoutApi_logout`, // 로그아웃
    },

    // 메뉴 컨트롤러 ( PortalUserMenuAPIController )
    MENU: {
      GET               : `${ baseApiUrl.USER }/portalUserMenuApi_getMenu?menuOid=`, // 메뉴 가져오기
      LIST_BY_ROLE      : `${ baseApiUrl.USER }/portalUserMenuApi_listByUserRole`, // 로그인 한 유저의 롤별 사용자 메뉴 리스트를 불러옵니다.
    },

    // 메세지 컨트롤러 ( PortalUserMenuAPIController )
    MESSAGE: {
      SEND_MESSAGE: `${ baseApiUrl.USER }/portalUserMessageApi_sendMsg`, // 메세지 발송
    },

    // 모두톡 관련 컨트롤러 ( PortalUserModutalkAPIController )
    MODUTALK: {
      GET: `${ baseApiUrl.USER }/PortalUserModutalkApi_get`, // 모두톡 회원 단건 조회
    },

    // 조직 안내 컨트롤러 ( PortalUserOrganizationAPIController )
    HOMEPAGE_GROUP: {
      LIST_ALL_HOMEPAGE_USER: `${ baseApiUrl.USER }/portalUserOrganizationAPI_homepageUserListAll`, // 조직구성원 리스트를 가져옵니다
    },

    // 기관 관련 컨트롤러 ( PortalUserOrgCodeAPIController )
    PORTAL_ORG: {
      GET : `${ baseApiUrl.USER }/portalUserOrgCodeApi_get`, // 기관 정보 가져오기
    },

    // 팝업 컨트롤러 ( PortalUserPopupAPIController )
    POPUP: {
      LIST_OPERATION: `${ baseApiUrl.USER }/portalUserPopupApi_operationList`, // 노출할 팝업 리스트 가져오기
      GET : `${ baseApiUrl.USER }/portalUserPopupApi_getPopup`, // 팝업 가져오기
    },

    // 포틀릿 컨트롤러 ( PortalUserPopupAPIController )
    PORTLET: {
      GET_YOUTUBE_PORTLET: `${ baseApiUrl.USER }/youtube/portalUserPortletApi_getYouTubePortlet`, // 메인 유투브포틀릿
    },

    // 스케줄 컨트롤러 ( PortalUserScheduleAPIController )
    SCHEDULE: {
      LIST    : `${ baseApiUrl.USER }/portalUserScheduleApi_list`, // 스케줄 리스트
      LIST_ALL: `${ baseApiUrl.USER }/portalUserScheduleAPI_listAll`, // 스케줄 전체 리스트
      GET     : `${ baseApiUrl.USER }/portalUserScheduleApi_get`, // 일정 상세내역 단건 가져오기
      DELETE     : `${ baseApiUrl.USER }/portalUserScheduleAPI_delete`, // 스케줄 단건 삭제
    },

    // 검색엔진 및 인기검색어 관련 컨트롤러 ( PortalUserSearchAPIController )
    SEARCH: {
      SEARCH   : `${ baseApiUrl.USER }/portalUserSearchApi_search`, // 사이트별 검색엔진 api 호출
      INSERT   : `${ baseApiUrl.USER }/portalUserSearchApi_insert`, // 인기검색어를 등록합니다.
      LIST_RANK: `${ baseApiUrl.USER }/portalUserSearchApi_listRank`, // 인기검색어 리스트를 가져옵니다.
    },

    // T검정 컨트롤러 ( PortalUserTtestAPIController )
    TTEST: {
      EXCEL_UPLOAD  : `${ baseApiUrl.USER }/portalUserTtestApi_excelUpload`, // T검정 엑셀 업로드
      EXEC_TTEST    : `${ baseApiUrl.USER }/portalUserTtestApi_execTtest`, // T검정 분석처리하기
      GET_GROUP_DATA: `${ baseApiUrl.USER }/portalUserTtestApi_getGroupData`, // T검정 그룹정의 가져오기
    },

    // 유저 컨트롤러 ( PortalUserAPIController )
    USER: {
      DUMMY: `${ baseApiUrl.USER }/portalUserApi_dummy`, // dummy 호출

      LIST_ALL_BY_FIND_ID : `${ baseApiUrl.USER }/portalUserApi_listAllByFindId`, // 유저 정보 조회 ( 아이디 찾기 )
      LIST_ALL_BY_FIND_PWD: `${ baseApiUrl.USER }/portalUserApi_listAllByFindPwd`, // 유저 정보 조회 ( 비밀번호 찾기 )
      GET     : `${ baseApiUrl.USER }/portalUserApi_getUser`, // 유저 oid로 해당 유저 정보를 가져옵니다.
      GET_ONLY: `${ baseApiUrl.USER }/portalUserApi_getOnlyUser`, // 유저의 기본정보만 반환합니다.
      INSERT: `${ baseApiUrl.USER }/portalUserApi_insertUser`, // 회원가입
      UPDATE_USER                             : `${ baseApiUrl.USER }/portalUserApi_updateUser`, // 유저 수정
      UPDATE_PASSWORD                         : `${ baseApiUrl.USER }/portalUserApi_updatePassword`, // 유저 비밀번호 수정
      UPDATE_STATUS_TYPE_FLAG_BY_USER_OID_LIST: `${ baseApiUrl.USER }/portalUserApi_updateUserStatusTypeFlagByUserOidList`, // 유저 상태값 변경
      EXIST_BY_ID: `${ baseApiUrl.USER }/portalUserApi_existById`, // 유저 아이디 중복 체크
      CHANGE_PWD_LATER                         : `${ baseApiUrl.USER }/portalUserApi_changePwdLater`, // 유저 비밀번호 수정

      CHECK_USER_CERT         : `${ baseApiUrl.USER }/portalUserApi_checkUserCert`, // 유저가 입력한 코드와 유저 OID로 인증 여부를 진행합니다.
      SEND_EMAIL_CERTIFICATION: `${ baseApiUrl.USER }/portalUserApi_sendEmailCertification`, // 비밀번호 찾기 > 인증 이메일을 전송 한다.
      EMAIL_CERT_CONFIRM      : `${ baseApiUrl.USER }/portalUserApi_emailCertConfirm`, // 비밀번호 찾기 > 이메일 인증 번호를 확인 한다.
      OTP_CERT_MESSAGE        : `${ baseApiUrl.USER }/portalUserApi_otpCertMessage`, // 회원가입 휴대폰 본인인증을 하기위하여 메세지 인증 번호를 확인 한다.
      CHECK_PASSWORD          : `${ baseApiUrl.USER }/portalUserApi_checkPassword`, // 비밀번호 확인 > 기존 비밀번호와 같은지 확인 하기 위해 추가.

      CHECK_DUPLICATE_NEIS_NO : `${ baseApiUrl.USER }/portalUserApi_checkDuplicateNeisNo`, // NEIS NO 중복 체크
      RESEND_EMAIL            : `${ baseApiUrl.USER }/portalUserApi_resendEmail`, // 회원 관리 - 일반 , 학생 , 학부모 , 교직원의 회원가입을 진행합니다.

      SEND_MESSAGE_FOR_OTP    : `${ baseApiUrl.USER }/portalUserApi_sendMessageForOtp`, // 일회용 로그인을 위한 인증번호를 문자로 보냅니다.
      SEND_MESSAGE_BY_PHONE   : `${ baseApiUrl.USER }/portalUserApi_sendMessageByPhone`, // 회원가입에서 본인핸드폰번호인지 인증 하기 위한 인증번호를 문자로 보냅니다.

      LIST_ALL_EXIST_USER_MASTER_ID                 : `${ baseApiUrl.USER }/portalUserApi_listAllExistUserMasterId`, //  해당 아이디가 masterUserId 혹은 relUserId에 존재 여부
      LIST_ALL_INTEGRATED_ACCOUNT                   : `${ baseApiUrl.USER }/portalUserApi_listAllIntegratedAccount`, //  통합된 계정 이력 전체 리스트
      GET_LAST_DATE_AND_OWNER_CHANGE_COUNT          : `${ baseApiUrl.USER }/portalUserApi_getLastDateAndOwnerChangeCount`, //  로그인한 아이디의 마지막으로 통합된 날짜와 ownerChange한 총 개수를 반환
      INSERT_OR_UPDATE_SUCCESS_YN_INTEGRATED_ACCOUNT: `${ baseApiUrl.USER }/portalUserApi_insertOrUpdateSuccessYnIntegratedAccount`, // master 계정에 rel 계정을 통합
      INTEGRATED_USER_PROCESS                       : `${ baseApiUrl.USER }/portalUserApi_integratedUserProcess`, // 일반, 학생, 학부모, 교직원 통합 인증
      INTEGRATED_USER_PROCESS_ONLY_GWSTAFF          : `${ baseApiUrl.USER }/portalUserApi_integratedUserProcessOnlyGwStaff`, // 강원 교직원 통합 인증
      INTEGRATED_REL_TO_MASTER_USER_PROCESS         : `${ baseApiUrl.USER }/portalUserApi_integratedRelToMasterUserProcess`, // master 계정에 rel 계정을 통합
    },

    // 본인인증
    SELF_CERT: {
      LIST_ALL      : `${ baseApiUrl.USER }/portalUserApi_selfCertListAll`, // 법정대리인 동의로 가입한 유저 정보 조회 ( 아이디 찾기 )
      INSERT        : `${ baseApiUrl.USER }/portalUserApi_selfCertInsert`, // 본인 인증 > 유저의 인증 정보를 저장
    },

    // 방문자 컨트롤러 ( PortalUserVisitAPIController )
    VISIT: {
      INSERT               : `${ baseApiUrl.USER }/portalUserVisitApi_insert`, // 접속 정보와 함께 유저 접속 정보를 저장합니다.
      GET_TODAY_TOTAL_COUNT: `${ baseApiUrl.USER }/portalUserVisitApi_getTodayAndTotalCount`, // 오늘과 전체카운트를 가져온다
    },

    // 사용자 로그인 조회
    EXCEL : {
      DOWNLOAD_LOGIN_HIST             : `${ baseApiUrl.USER }/portalManagerExcelApi_loginHistoryList`
    }

  },

  // 관리자
  MANAGER: {

    // 앱 관리 컨트롤러 ( PortalManagerAppAPIController )
    APP: {
      LIST_APP_RELEASE                    : `${ baseApiUrl.MANAGER }/portalManagerAppApi_listAppRelease`, // 앱 관리 리스트
      INSERT_OR_UPDATE_APP_RELEASE        : `${ baseApiUrl.MANAGER }/portalManagerAppApi_saveAppRelease`, // 앱 관리 등록 / 수정
      COPY_TO_UPDATE_APP_RELEASE          : `${ baseApiUrl.MANAGER }/portalManagerAppApi_copyToUpdateAppRelease`, // 앱 버전 정보를 가져온 후 앱 정보에 업데이트
      INSERT_OR_UPDATE_APP_RELEASE_VERSION: `${ baseApiUrl.MANAGER }/portalManagerAppApi_saveAppReleaseVersion`, // 앱 버전 누적 등록
      UPDATE_USE_YN                       : `${ baseApiUrl.MANAGER }/portalManagerAppApi_updateUseYn`, // 상태 변경하기
      APP_VERSION_UPDATE_USE_YN           : `${ baseApiUrl.MANAGER }/portalManagerAppApi_appVersionUpdateUseYn`, // 앱 버전 상태 변경 및 배포일 입력
      DELETE_APP_RELEASE                  : `${ baseApiUrl.MANAGER }/portalManagerAppApi_deleteAppRelease`, // 앱 정보 삭제
      EXIST_APP_NAME                      : `${ baseApiUrl.MANAGER }/portalManagerAppApi_existAppName`, // 앱이름 중복체크
      GET_APP_RELEASE                     : `${ baseApiUrl.MANAGER }/portalManagerAppApi_getAppRelease`, // 앱 정보 가져오기
      LIST_APP_RELEASE_VERSION            : `${ baseApiUrl.MANAGER }/portalManagerAppApi_listAppReleaseVersion`, // 앱 버전 리스트
      GET_APP_RELEASE_VERSION             : `${ baseApiUrl.MANAGER }/portalManagerAppApi_getAppReleaseVersion`, // 앱 버전 정보 가져오기

      LIST_APP_DOMAIN            : `${ baseApiUrl.MANAGER }/portalManagerAppApi_listAppDomain`, // 도메인 관리 리스트
      LIST_ALL_APP_DOMAIN        : `${ baseApiUrl.MANAGER }/portalManagerAppApi_listAllAppDomain`, // 도메인 관리 전체 리스트
      INSERT_OR_UPDATE_APP_DOMAIN: `${ baseApiUrl.MANAGER }/portalManagerAppApi_saveAppDomain`, // 도메인 관리 등록 / 수정
      GET_APP_DOMAIN             : `${ baseApiUrl.MANAGER }/portalManagerAppApi_getAppDomain`, // 도메인 정보 가져오기
      EXIST_DOMAIN_NAME          : `${ baseApiUrl.MANAGER }/portalManagerAppApi_existDomainName`, // 도메인명 중복체크
      DELETE_APP_DOMAIN          : `${ baseApiUrl.MANAGER }/portalManagerAppApi_deleteAppDomain`, // 도메인 삭제

      GET_APP_DOWN_AUDIT     : `${ baseApiUrl.MANAGER }/portalManagerAppApi_getAppDown`, // 앱 다운로드 이력 가져오기
      LIST_ALL_APP_DOWN_AUDIT: `${ baseApiUrl.MANAGER }/portalManagerAppApi_getAppListAll`, // 앱 누적 다운로드 통계 가져오기
    },

    // 게시판 컨트롤러 ( PortalManagerBoardAPIController )
    BOARD: {
      GET                 : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_getBoardInfo`, // 게시판 가져오기
      LIST                : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_boardList`, // 게시판 리스트
      UPDATE              : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_updateBoard`, // 게시판 수정
      DELETE_AND_INSERT_PROPERTY: `${ baseApiUrl.MANAGER }/portalManagerBoardApi_deleteAndInsertProperty`, // 게시판 속성 수정
      TERMINATE_BOARD_OID_LIST  : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_terminateBoardList`, // 게시판 완전 삭제
    },

    // POSTING
    POSTING : {
      LIST              : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_listPosting`, // 게시글 리스트
      VIEW_WITH_PREV_AND_NEXT   : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_viewWithPrevAndNext`, // 게시글 상세보기
      GET               : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_getPosting`, // 게시글 가져오기
      INSERT_OR_UPDATE  : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_savePosting`, // 게시글 등록/수정
      DELETE            : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_deletePosting`, // 게시글 단건 삭제
      DELETE_POSTING_LIST       : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_deletePostingList`, // 게시글 다건 삭제
      RESTORE           : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_restorePosting`, // 게시글 복구
      TERMINATE         : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_terminatePosting`, // 게시글 완전 삭제
      CHECK_POSTING_PWD         : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_pwdCheck`, // 게시글 비밀번호 일치여부
      RECOMMEND_ACTION          : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_recommendAction`, // 게시글 추천기능
      CHECK_FORBIDDEN_WORD      : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_checkForbidden`, // 금칙어 확인
    },

    COMMENT: {
      LIST              : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_listComment`, // 답글 리스트
      INSERT_OR_UPDATE  : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_insertOrUpdateComment`, // 답글 등록/수정
      DELETE            : `${ baseApiUrl.MANAGER }/portalManagerBoardApi_deleteComment`, // 답글 삭제
    },

    // 코드 관리 컨트롤러 ( PortalManagerCodeAPIController )
    CODE: {
      LIST_ALL        : `${ baseApiUrl.MANAGER }/portalManagerCode_listAll`, // 코드 전체 목록
      LIST_FIRST_DEPTH: `${ baseApiUrl.MANAGER }/portalManagerCode_listFirstDept`, // 1레벨인 코드 목록과 코드 목록에 해당하는 하위 레벨 개수를 불러옵니다
      LIST_LOW_DEPTH  : `${ baseApiUrl.MANAGER }/portalManagerCode_listLowDept`, // 하위 코드 목록을 가져옵니다.
    },

    // 엑셀 관련 컨트롤러 ( PortalManagerExcelAPIController )
    EXCEL: {
      EXCEL_FILE_TO_JSON               : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_excelFileToJson`, // 엑셀파일을 json 으로 변환하여 HashMap 으로 반환합니다.
      DOWNLOAD_SMS_SEND_HIST_LIST      : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_smsSendHistList`, // sms 발송내역 엑셀다운로드
      DOWNLOAD_NOTI_TALK_SEND_HIST_LIST: `${ baseApiUrl.MANAGER }/portalManagerExcelApi_notiTalkSendHistList`, // 알림톡 발송내역 엑셀다운로드
      DOWNLOAD_MAIL_SEND_HIST_LIST     : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_mailSendHistList`, // 메일 발신 내역 엑셀 다운로드
      DOWNLOAD_TTEST                   : `${ baseApiUrl.MANAGER }/ttestExcelDownload`, // t 검정 엑셀다운로드
      DOWNLOAD_USER_LIST               : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_userList`, // 회원 목록 엑셀 다운로드
      DOWNLOAD_WORKSPACE_USER_LIST     : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_workspaceUserList`, // 워크스페이스 회원 목록 엑셀 다운로드
      DOWNLOAD_AGENCY_CODE_LIST        : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_agencyCodeList`, // 교육포탈 기준정보 관리 > 기관코드 관리 > 엑셀다운로드

      DOWNLOAD_MEM_CHART_DATE_IST     : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_listMemChartDate`, // 포탈 통계 관리 > 회원 접속 현황 : 기간별 > 엑셀다운로드
      DOWNLOAD_STATS_ORG_DETAIL_LIST  : `${ baseApiUrl.MANAGER }/portalManagerExcelApi_statsOrgDetailList`, // 포탈 통계 관리 > 회원 접속 현황 : 기관별 접속 현황 상세정보  > 엑셀다운로드
      DOWNLOAD_STATS_SYSTEM_VISIT_LIST: `${ baseApiUrl.MANAGER }/portalManagerExcelApi_statsSystemVisitList`, // 포탈 통계 관리 > 시스템 방문 현황 > 엑셀다운로드
    },

    // 금칙어 관리 컨트롤러 ( PortalManagerForbiddenAPIController )
    FORBIDDEN_WORD: {
      LIST  : `${ baseApiUrl.MANAGER }/portalManagerForbiddenApi_listForbidden`, // 금칙어 리스트
      INSERT: `${ baseApiUrl.MANAGER }/portalManagerForbiddenApi_insertForbidden`, // 금칙어을 등록합니다.
      DELETE: `${ baseApiUrl.MANAGER }/portalManagerForbiddenApi_deleteForbidden`, // 금칙어 삭제
    },

    // 구글 관련 관리 컨트롤러 ( PortalManagerGoogleAPIController )
    GOOGLE: {
      LIST_GOOGLE_USER_CERT_AUDIT: `${ baseApiUrl.MANAGER }/portalManagerCode_listAll`, // 구글워크스페이스 > 사용자 관리 > 사용자 이력 관리 의 구글 생성 이력 리스트를 불러옵니다.
    },

    // 홈페이지 - 연혁 및 주요업무 관리 컨트롤러 ( PortalManagerHomepageHistoryAPIController )
    HOMEPAGE_HISTORY: {
      LIST          : `${ baseApiUrl.MANAGER }/portalManagerHomepageHistoryApi_list`, // 연혁 또는 주요 업무 페이지 리스트를 불러옵니다.
      GET           : `${ baseApiUrl.MANAGER }/portalManagerHomepageHistoryApi_get`, // 연혁 또는 주요 업무 단건을 가져옵니다.
      INSERT        : `${ baseApiUrl.MANAGER }/portalManagerHomepageHistoryApi_insert`, // 연혁 또는 주요 업무 등록
      UPDATE        : `${ baseApiUrl.MANAGER }/portalManagerHomepageHistoryApi_update`, // 연혁 또는 주요 업무 수정
      DELETE        : `${ baseApiUrl.MANAGER }/portalManagerHomepageHistoryApi_delete`, // 연혁 또는 주요 업무 삭제
      CHECK_ORDER_NO: `${ baseApiUrl.MANAGER }/portalUserHomepageHistoryApi_isOrderNoCheck`, // 연혁 orderNo 중복검사
    },

    // 관리자 회원 IP 관리 컨트롤러 ( PortalManagerManagerIpAPIController )
    IP: {
      LIST_JOIN_USER    : `${ baseApiUrl.MANAGER }/portalManagerManagerIpApi_listJoinUser`, // 사용자와 JOIN한 관리자 회원 IP 리스트
      LIST              : `${ baseApiUrl.MANAGER }/portalManagerManagerIpApi_list`, // 관리자 회원 IP 리스트
      UPDATE_USE_YN_ALL : `${ baseApiUrl.MANAGER }/portalManagerManagerIpApi_updateUseYnAll`, // 사용여부 상태값을 변경합니다.
      DELETE            : `${ baseApiUrl.MANAGER }/portalManagerManagerIpApi_delete`, // 관리자 회원 IP 삭제
      DELETE_BY_USER_OID: `${ baseApiUrl.MANAGER }/portalManagerManagerIpApi_deleteByUserOid`, // 관리자 회원 IP 다건 삭제
      INSERT_OR_UPDATE  : `${ baseApiUrl.MANAGER }/portalManagerManagerIpApi_save`, // 관리할 유저의 ip를 저장합니다.
    },

    // 메뉴 관리 컨트롤러 ( PortalManagerMenuAPIController )
    MENU: {
      LIST_ALL_MENU_TREE: `${ baseApiUrl.MANAGER }/portalManagerMenuApi_listMenuTree`, // 자식 정보를 가지고 있는 트리 구조의 메뉴 리스트를 가져옵니다.
      INSERT_OR_UPDATE  : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_insertOrUpdateMenu`, // 메뉴 등록/수정
      DELETE            : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_deleteMenu`, // 메뉴 삭제
      GET               : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_getMenu?menuOid=`, // 단건의 메뉴를 불러옵니다.
      LIST_BY_ROLE      : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_listByRole`, // 단건의 메뉴를 불러옵니다.
      MOVE              : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_moveMenu`, // 트리 구조의 메뉴를 이동시킵니다.
    },

    // 메세지 발송 관리 컨트롤러 ( PortalManagerMessageAPIController )
    MESSAGE: {
      SEND_MSG          : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_sendMsg`, // SMS를 발송합니다.
      SEND_EMAIL        : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_sendEmail`, // 지정 사용자들에게 이메일을 발송합니다.
      LIST_SEND_HIST    : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_msgHistList`, // 최근 보낸번호, 카카오 알림톡 / SMS 발송내역을 가져옵니다.
      LIST_ALL_SEND_HIST: `${ baseApiUrl.MANAGER }/portalManagerMessageApi_msgHistListAll`, // 최근 보낸번호, 카카오 알림톡 / SMS 발송내역을 모두 가져옵니다.

      LIST_BOOKING            : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_msgResList`, // 예약문자 리스트를 가져옵니다.
      SEND_MESSAGE_BOOKING_NOW: `${ baseApiUrl.MANAGER }/portalManagerMessageApi_sendMessageBookingNow`, // 예약문자를 현재 시간으로 바로 전송합니다.
      DELETE_BOOKING          : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_delete`, // 예약문자 단건을 취소합니다.
      DELETE_BULK_BOOKING     : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_deleteBulk`, // 예약문자 목록을 취소합니다.

      LIST_MESSAGE_TEMPLATE            : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_listMsgTemplate`, // 메세지 템플릿 페이지 리스트를 가져옵니다.
      GET_MESSAGE_TEMPLATE             : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_getMsgTemplate`, // 메세지 템플릿 단건을 가져옵니다.
      INSERT_OR_UPDATE_MESSAGE_TEMPLATE: `${ baseApiUrl.MANAGER }/portalManagerMessageApi_updateMsgTemplate`, // 메세지 템플릿 단건을 등록/수정합니다.
      UPDATE_USE_YN_MESSAGE_TEMPLATE   : `${ baseApiUrl.MANAGER }/portalManagerMessageApi_updateUseYnMsgTemplate`, // 메세지 템플릿의 사용여부를 수정합니다.
    },

    // 모두톡 관련 컨트롤러 ( PortalManagerModutalkAPIController )
    MODUTALK: {
      LIST_WITH_USER_ID_ASSIGNED: `${ baseApiUrl.MANAGER }/portalManagerModutalkApi_listWithUserIdAssigned`, // 모두톡 userId 를 사용자 NEIS_ID 로 사용중인 현황을 포함하여 모두톡 목록을 조회합니다.
      LIST_MODUTALK_USER_HIST   : `${ baseApiUrl.MANAGER }/portalManagerModutalkApi_listPortalModutalkHist`, // 나이스 기관 정보 변경 이력을 가져옵니다.
      MANUAL_BATCH_MODUATLK_SYNC: `${ baseApiUrl.MANAGER }/portalManagerModutalkApi_modutalkSyncManualBatch`, // 기준정보 관리 > 기관코드 관리 에서 수동으로 모두톡 기관과 사용자를 배치합니다.
    },

    // 운영 관리 컨트롤러 ( PortalManagerOperateAPIController )
    // 즐겨찾기
    FAVORITE: {
      INSERT  : `${ baseApiUrl.MANAGER }/portalManagerOperateApi_insertFavorite`, // 즐겨찾기를 추가합니다.
      DELETE  : `${ baseApiUrl.MANAGER }/portalManagerOperateApi_deleteFavorite`, // 즐겨찾기를 삭제합니다.
      LIST_ALL: `${ baseApiUrl.MANAGER }/portalManagerOperateApi_listAllFavorite`, // 즐겨찾기 전체 리스트를 불러옵니다.
    },

    // 조직도 컨트롤러 ( PortalManagerOrganizationAPIController )
    // 홈페이지 조직도
    HOMEPAGE_GROUP: {
      LIST_ALL_TREE   : `${ baseApiUrl.MANAGER }/portalManagerOrganizationAPI_listOrganizationTree`, // 조직도 구성원 리스트를 불러옵니다.
      DELETE          : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_deleteOrganization`, // 조직도 구성을 삭제합니다.
      INSERT_OR_UPDATE: `${ baseApiUrl.MANAGER }/portalManagerMenuApi_insertOrUpdateOrganization`, // 조직도 구성 등록 및 수정합니다
      GET             : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_getHomepageGroupInfo`, // 조직상세보기와 해당조직의 구성원을 같이가져옵니다

      LIST_HOMEPAGE_GROUP_USER        : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_homepageGroupUserList`, // 조직구성원 리스트를 가져옵니다
      GET_HOMEPAGE_GROUP_USER         : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_getHomepageGroupUserInfo`, // 조직구성원 정보를 가져옵니다
      INSERT_HOMEPAGE_GROUP_USER      : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_insertHomepageGroupUserInfo`, // 그룹에 조직구성원을 추가합니다
      UPDATE_HOMEPAGE_GROUP_USER_ORDER: `${ baseApiUrl.MANAGER }/portalManagerMenuApi_updateHomepageGroupUserOrder`, // 조직구성원 정렬을 업데이트합니다.
      UPDATE_HOMEPAGE_GROUP_USER      : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_updateHomepageGroupUser`, // 조직구성원 정보를 업데이트 합니다
      DELETE_HOMEPAGE_GROUP_USER      : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_deleteHomepageGroupUserInfo`, // 그룹에 조직구성원을 삭제합니다
      DELETE_BULK_HOMEPAGE_GROUP_USER : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_deleteBulkHomepageGroupUserInfo`, // 조직구성원을 여러명 삭제합니다
      CHECK_ORDER_NO                  : `${ baseApiUrl.MANAGER }/portalManagerMenuApi_isOrderNoCheck`, // 조직구성원 orderNo 중복검사
    },

    // 기관 코드 관리 컨트롤러 ( PortalManagerOrgCodeAPIController )
    PORTAL_ORG: {
      LIST                          : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_list`, // 기관 리스트
      GET                           : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_get`, // 기관 단건 조회
      INSERT                        : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_insert`, // 기관 등록
      UPDATE                        : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_update`, // 기관 수정
      UPDATE_WORKSPACE_ORG          : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_updateWorkspaceOrg`, // 기관의 구글워크스페이스 조직만 수정
      DELETE                        : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_delete`, // 기관 단건 삭제
      DELETE_BY_CND                 : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_deleteList`, // 기관 다건 삭제
      COUNT_USER_USING_ORG_CODE     : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_countUserUsingOrgCode`, // 해당 기관 코드를 사용중인 회원 수를 알려줍니다.
      COUNT_USER_USING_ORG_CODE_LIST: `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_countUserUsingOrgCodeList`, // 해당 기관 코드를 사용중인 회원 수를 알려줍니다.
      LIST_DEPARTMENT_AND_CITY      : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_listDepartMentAndCityInfo`, // 사용자 통계 - 검색용 역에 맞는 교육청, 도시(시, 군, 구) 리스트를 가져옵니다.
      LIST_ALL_EXIST                : `${ baseApiUrl.MANAGER }/portalManagerOrgCodeApi_listAllExist`, // 존재하는 특정 기관정보를 가져옵니다.
    },

    // 팝업 관리 컨트롤러 ( PortalManagerPopupAPIController )
    POPUP: {
      INSERT_OR_UPDATE            : `${ baseApiUrl.MANAGER }/portalManagerPopupApi_savePopup`, // 팝업을 저장합니다.
      DELETE_BY_OID_LIST          : `${ baseApiUrl.MANAGER }/portalManagerPopupApi_deletePopup`, // 여러개의 팝업을 삭제합니다.
      UPDATE_USE_YN               : `${ baseApiUrl.MANAGER }/portalManagerPopupApi_updateUseYn`, // 사용 상태값을 변경합니다.
      GET_POPUP_WITH_PREV_AND_NEXT: `${ baseApiUrl.MANAGER }/portalManagerPopupApi_getPopupWithPrevAndNext`, // 이전 팝업, 다음 팝업 객체를 가져옵니다.
      GET                         : `${ baseApiUrl.MANAGER }/portalManagerPopupApi_getPopup`, // 팝업을 가져옵니다.
      LIST                        : `${ baseApiUrl.MANAGER }/portalManagerPopupApi_popupList`, // 팝업 페이지 리스트를 가져옵니다.
      LIST_ALL                    : `${ baseApiUrl.MANAGER }/portalManagerPopupApi_listAllPopup`, // 팝업 전체 리스트를 가져옵니다.
      LIST_OPERATION              : `${ baseApiUrl.MANAGER }/portalManagerPopupApi_operationList`, // 노출할 팝업 리스트
    },

    // 권한 관리 컨트롤러 ( PortalManagerRoleAPIController )
    ROLE: {
      LIST                       : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_roleList`, // 권한 리스트
      LIST_ALL                   : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_roleListAll`, // 권한 전체 리스트
      UPDATE                     : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_updateRole`, // 권한 수정
      INSERT                     : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_insertRole`, // 권한 등록
      DELETE_AND_INSERT_ROLE_USER: `${ baseApiUrl.MANAGER }/portalManagerRoleApi_deleteAndInsertRoleUser`, // ROLE USER 삭제 후 재등록
      DELETE                     : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_deleteRole`, // 권한 삭제

      LIST_ALL_ROLE_USER         : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_getRoleUser`, // 권한 그룹 회원 리스트
      EXIST_CHECK_EXCLUDE_SELF   : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_existCheckExcludeSelf`, // 자기 자신을 제외하고 그룹명이 일치하는 객체가 있는지 검사하여 있으면 true 를 반환
      DELETE_AND_INSERT_ROLE_MENU: `${ baseApiUrl.MANAGER }/portalManagerRoleApi_deleteAndInsertRoleMenu`, // ROLE MENU 삭제 후 재등록
      ADD_ROLE_USER              : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_addRoleUser`, // 사용자에게 권한을 부여합니다.
      DELETE_ROLE_USER_REL       : `${ baseApiUrl.MANAGER }/portalManagerRoleApi_deleteRoleUserRel`, // 사용자에게 권한을 삭제합니다.
    },

    // 스케줄 관리 컨트롤러 ( PortalManagerScheduleAPIController )
    SCHEDULE: {
      INSERT_OR_UPDATE: `${ baseApiUrl.MANAGER }/portalManagerScheduleAPI_insertOrUpdate`, // 스케줄 등록/수정
      GET             : `${ baseApiUrl.MANAGER }/portalManagerScheduleAPI_get`, // 스케줄 조회
      LIST            : `${ baseApiUrl.MANAGER }/portalManagerScheduleAPI_list`, // 스케줄 리스트
      LIST_ALL        : `${ baseApiUrl.MANAGER }/portalManagerScheduleAPI_listAll`, // 스케줄 전체 리스트
      DELETE          : `${ baseApiUrl.MANAGER }/portalManagerScheduleAPI_delete`, // 스케줄 단건 삭제
      DELETE_BULK     : `${ baseApiUrl.MANAGER }/portalManagerScheduleAPI_deleteBulk`, // 스케줄 다건 삭제
    },

    // 통계 컨트롤러 ( PortalManagerStatsAPIController )
    STATS: {
      LIST_MONTH_STATUS_USER    : `${ baseApiUrl.MANAGER }/portalManagerUserApi_listMonthStatusUser`, // 해당 날짜에 맞는 사용자타입별 통계 반환
      LIST_AREA_STATS_USER      : `${ baseApiUrl.MANAGER }/portalManagerUserApi_listAreaStatsUser`, // 지역별 사용자 통계
      LIST_ORG_STATS_USER       : `${ baseApiUrl.MANAGER }/portalManagerUserApi_listOrgStatsUser`, // 기관별 구분별 사용자 통계
      LIST_ORG_DETAIL_STATS_USER: `${ baseApiUrl.MANAGER }/portalManagerUserApi_listOrgDetailStatsUser`, // 기관별 상세정보
      LIST_ALL_VISIT_STATS_AUDIT: `${ baseApiUrl.MANAGER }/portalManagerUserApi_listAllAuditVisitStat`, // 시스템 방문현황
    },

    // 연동 팝업 관리 컨트롤러 ( PortalManagerSyncPopupAPIController )
    SYNC_POPUP: {
      INSERT_OR_UPDATE: `${ baseApiUrl.MANAGER }/portalManagerSyncPopupApi_savePopup`, // 팝업 등록/수정
      GET             : `${ baseApiUrl.MANAGER }/portalManagerSyncPopupApi_getPopup`, // 팝업 조회
      LIST            : `${ baseApiUrl.MANAGER }/portalManagerSyncPopupApi_popupList`, // 팝업 리스트
      UPDATE_USE_YN   : `${ baseApiUrl.MANAGER }/portalManagerSyncPopupApi_updateUseYn`, // 팝업 상태값 수정
      DELETE          : `${ baseApiUrl.MANAGER }/portalManagerSyncPopupApi_deletePopup`, // 팝업 삭제
    },

    // 연동 공지 관리 컨트롤러 ( PortalManagerCodeAPIController )
    SYNC_POSTING: {
      INSERT_OR_UPDATE: `${ baseApiUrl.MANAGER }/portalManagerSyncPostingApi_savePosting`, // 공지사항 등록/수정
      LIST            : `${ baseApiUrl.MANAGER }/portalManagerSyncPostingApi_listPosting`, // 공지사항 리스트
      GET             : `${ baseApiUrl.MANAGER }/portalManagerSyncPostingApi_getPosting`, // 공지사항 조회
      DELETE          : `${ baseApiUrl.MANAGER }/portalManagerSyncPostingApi_deleteSyncPosting`, // 공지사항 삭제
      DELETE_BULK     : `${ baseApiUrl.MANAGER }/portalManagerSyncPostingApi_deletePosting`, // 공지사항 다건 삭제
    },

    // T검정 관리 컨트롤러 ( PortalManagerTtestAPIController )
    TTEST: {
      LIST_HISTORY: `${ baseApiUrl.MANAGER }/ttest/historyList`, // 코드 전체 목록
    },

    // 사용자 관리 컨트롤러 ( PortalManagerUserAPIController )
    USER: {
      LIST                                    : `${ baseApiUrl.MANAGER }/portalManagerUserApi_listUser`, // 사용자 리스트
      INSERT_OR_UPDATE                        : `${ baseApiUrl.MANAGER }/portalManagerUserApi_insertOrUpdateUser`, // 사용자 등록/수정
      UNBLOCK_USER                            : `${ baseApiUrl.MANAGER }/portalManagerUserApi_unBlockUser`, // 로그인 차단을 강제로 해제시킵니다.
      UPDATE_PASSWORD_SEND_SMS                : `${ baseApiUrl.MANAGER }/portalManagerUserApi_updatePasswordSendSMS`, // 비밀번호 재설정하고 SMS 전송합니다.
      GET                                     : `${ baseApiUrl.MANAGER }/portalManagerUserApi_getUser`, // 사용자 OID로 조회
      GET_BY_USER_ID                          : `${ baseApiUrl.MANAGER }/portalManagerUserApi_getUserByUserId`, // 사용자 ID로 조회
      EXIST_USER_MASTER_ID                    : `${ baseApiUrl.MANAGER }/portalManagerUserApi_existUserMasterId`, // userId로 해당 아이디가 통합이 되어있는지 확인하여 Boolean 값으로 반환합니다.
      INTEGRATED_REL_TO_MASTER_USER_PROCESS   : `${ baseApiUrl.MANAGER }/portalManagerUserApi_integratedRelToMasterUserProcess`, // master 계정에 rel 계정을 통합합니다.
      UPDATE_STATUS_TYPE_FLAG_BY_USER_OID_LIST: `${ baseApiUrl.MANAGER }/portalManagerUserApi_updateUserStatusTypeFlagByUserOidList`, // 다건 유저의 상태값을 변경합니다.
      LIST_USER_AUDIT                         : `${ baseApiUrl.MANAGER }/portalManagerUserApi_userAuditList`, // 사용자 이력 페이지 목록을 호출합니다.
      DELETE_BY_CND                           : `${ baseApiUrl.MANAGER }/portalManagerUserApi_deleteByCnd`, // 회원 정보를 삭제합니다.
      CHECK_DUPLICATE_NEIS_NO                 : `${ baseApiUrl.MANAGER }/portalManagerUserApi_checkDuplicateNeisNo`, // NEIS NO 중복 체크
      LIST_ALL_INTEGRATED_ACCOUNT             : `${ baseApiUrl.MANAGER }/portalManagerUserApi_listAllIntegratedAccount`, // 통합 계정 목록 리스트
      UPDATE_NEIS_NO                          : `${ baseApiUrl.MANAGER }/portalUserApi_updateUserNeisNo`, // 사용자의 neis 개인번호를 업데이트 합니다.
    },

    // 유튜브 영상 관리 컨트롤러 ( PortalManagerYoutubeManageAPIController )
    YOUTUBE: {
      INSERT: `${ baseApiUrl.MANAGER }/portalManagerYoutubeManageApi_insert`, // 유튜브 동기화 등록
      GET   : `${ baseApiUrl.MANAGER }/portalManagerYoutubeManageApi_get`, // 유튜브 동기화 정보 가져오기
      LIST  : `${ baseApiUrl.MANAGER }/portalManagerYoutubeManageApi_list`, // 유튜브 동기화 리스트
    },

  },

};

export default portalApiUrl;
