import Cookies from "js-cookie";

export const state = () => ( {

    //로그인 유저
    loginUser: {

        userId      : "",
        userName    : "",
        userOid     : "",
        userEmail   : "",
        userRoleOids: [],

        userBirthDay    : "",
        userType        : "",
        orgName         : "",
        orgCode         : "",

        isManager: false,
        isNoDuplicateCheck: false,
        loginLastDate     : "",
        dormantStartDate  : "",
        isSelfCerted      : false,

        loginAuditOid     : "",
        lastLoginUserIp   : "",

        // 구글 워크스페이스
        googleWorkspaceId     : "",
        googleOrgUnitId       : "",
        isSameOrgUnit         : false,
        googleUserInfo        : {},
        googleInsertStatusFlag: "",
        isGoogleApiConnected  : true,

        // 중복 로그인 설정 여부
        isDuplicateLoginCheck : false,
    }
} )

export const mutations = {

	// 로그인하고 나면 로그인 유저로 loginUser를 맞춰준다
	LOGIN_USER( state, loginUser ) {
		state.loginUser = loginUser;
	},

	SET_LOGIN_AUDIT_OID( state , loginAuditOid ) {

		state.loginUser.loginAuditOid = loginAuditOid;
	},

  LOGOUT_USER( state ) {
    state.loginUser = {
      userId      : "",
      userName    : "",
      userOid     : "",
      userEmail   : "",
      userRoleOids: [],

      userBirthDay    : "",
      userType        : "",
      orgName         : "",
      orgCode         : "",

      isManager: false,
      isNoDuplicateCheck: false,
      loginLastDate     : "",
      dormantStartDate  : "",
      isSelfCerted      : false,

      loginAuditOid     : "",
      lastLoginUserIp   : "",

      // 구글 워크스페이스
      googleWorkspaceId     : "",
      googleOrgUnitId       : "",
      isSameOrgUnit         : false,
      googleUserInfo        : {},
      googleInsertStatusFlag: "",
      isGoogleApiConnected  : true,

      // 중복 로그인 설정 여부
      isDuplicateLoginCheck : false,
    }
  }

}

export const actions = {

  logout( { commit } ) {

    const URL_LOGOUT = "/api/user/v1/portalUserLogoutApi_logout";
    $nuxt.$axios.post( URL_LOGOUT, {} ).then( res => {

        commit( "LOGOUT_USER" );

        sessionStorage.clear();
        Cookies.remove( "KWE_USERID" );
        Cookies.remove( "KWE_LOGIN_TYPE" );
        Cookies.remove( "LAO" );

        document.location.href = "/portal/user/main/user_main";
    } );

  },

  logoutAndGoLoginPage( { commit } ) {

    const URL_LOGOUT = "/api/user/v1/portalUserLogoutApi_logout";
    $nuxt.$axios.post( URL_LOGOUT, {} ).then( res => {

        commit( "LOGOUT_USER" );

        sessionStorage.clear();
        Cookies.remove( "KWE_USERID" );
        Cookies.remove( "KWE_LOGIN_TYPE" );
        Cookies.remove( "LAO" );

        document.location.href = "/portal/user/login/user_login";
    } );

  },

	loginUser( { commit }, loginUser ) {

		commit( "LOGIN_USER", loginUser );
	},

	setLoginAuditOid ( { commit }, loginAuditOid ) {

		commit( "SET_LOGIN_AUDIT_OID", loginAuditOid );
	},



}

export const getters = {

	loginUserInfo( state ) {

		if ( $nuxt.$validate.isEmpty( state.loginUser ) ) {
			$nuxt.$store.dispatch( "common/login/loginUser" );
		}

		return state.loginUser;
	}
}
